<template>
  <div class="http404">
    <div class="img">
      <img src="@/assets/images/no_html/400.png">
    </div>
    <div class="text">
      <div class="tit">400</div>
      <div class="tip">请求出错</div>
      <el-button class="btnBgColor_blue" type="primary" @click="toHome">返回首页</el-button>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex"
  export default {
    name: '400',
    computed:{
      ...mapState({
      	tabList: state => state.tabsArr.tabList
      })
    },
    methods: {
      toHome() {
        this.$router.push(this.tabList[0].url)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .http404 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .img {
      width: 450px;
      height: 450px;
    }

    .text {
      margin-left: 60px;
      padding-top: 100px;

      .tit {
        color: #E51D41;
        font-size: 50px;
        font-weight: bold;
      }

      .tip {
        font-size: 16px;
        font-weight: 400;
        color: #909399;
        padding: 20px 0 35px 0;
      }
    }
  }
</style>